import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { locationEvent } from '../dto/orwi-location';

@Injectable({
  providedIn: 'root'
})
export class LocationEventsService {

  currentLocationEvent = new Subject<locationEvent>()
  selectedLocationEvent = new Subject<locationEvent>()
  selectMapPoisition = new Subject<any>()
  googleMapInitialized = new Subject<any>()
  addressSaved = new Subject<any>()
  addressResolving = new Subject<any>()
  addressResolved = new Subject<any>()

  constructor() { }
}
