import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { OrwiScratchComponent } from './orwi-scratch.component';

@Injectable({
  providedIn: 'root'
})
export class OrwiScratchService {


  component: ComponentRef<OrwiScratchComponent>

  constructor(
    private app: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
    ) {}

  showComponent(): OrwiScratchComponent {
    let newNode = document.createElement('div');
    document.getElementById('actionContainer').appendChild(newNode);
    let resolver = this.componentFactoryResolver.resolveComponentFactory(OrwiScratchComponent);
    this.component = resolver.create(this.injector, [], newNode);
    this.app.attachView(this.component.hostView);
    return this.component.instance
  }

  destroyComponent() {
    this.component.destroy()
    this.component = undefined
   }
 
}
