import { Injectable } from '@angular/core';
import { Feed, FeedCategory, ServiceType } from '../dto/orwi-home';
import { orwiLocation } from '../dto/orwi-location';
import { FolioService } from '../folio/folio.service';
import { GlobalService } from '../global.service';
import { OrwiService } from '../orwi.service';
import { UserService } from '../user/user.service';


@Injectable({
  providedIn: 'root'
})
export class FeedService {

  activeMode: modeValues = "delivery"
  activeFeed: Feed
  filterModalStatus: 'open' | 'close' = "close"
  categories: FeedCategory[] = []
  serviceTypes: ServiceType[] = []
  searchText = ""
  feedLoaded : boolean = false;

  constructor(
    private fs: FolioService,
    private os: OrwiService, private us: UserService, private glb: GlobalService) {


    this.getCatagories()

  }


  getFeed(skip, limit, mode, location: orwiLocation, maxDistance = 20): Promise<Feed[]> {
    this.feedLoaded = false;
    return new Promise((resolve, reject) => {

      let selectedCategories = this.categories.filter(o => o.selected)
      let cats = []
      for (const iterator of selectedCategories) {
        cats.push(iterator.id)
      }

      let selecetedServiceTypes = this.serviceTypes.filter(o => o.selected)
      let sTypes = []
      for (const iterator of selecetedServiceTypes) {
        sTypes.push(iterator.id)
      }

      let lat = location?.latitude || 0
      let lng = location?.longitude || 0
      let filter = {
        searchText: this.searchText,
        mode: this.activeMode,
        categories: cats,
        serviceTypes: sTypes,
        paymentTypes: [],

      }
      console.log({ skip: skip, limit: limit, latitude: lat, longitude: lng, filter: filter })
      this.os.serviceRequestPromise("/api/feed/getFeed", { maxDistance: parseFloat(maxDistance.toFixed(2)), skip: skip, limit: limit, latitude: lat, longitude: lng, filter: filter }, this.us.token).then((o: any) => {
        if (o.response) {
          console.log(o.response.length)
          this.feedLoaded = true;
          resolve(o.response)
        }
      })
    })
  }

  getCatagories(): Promise<FeedCategory[]> {
    return new Promise((resolve, reject) => {
      this.os.serviceRequestPromise("/api/category/getCategories", {}, this.us.token).then((o: any) => {
        if (o.response) {
          this.categories = o.response
          resolve(o.response)
        }

      })
    })
  }

  loadServiceTypes() {


    this.serviceTypes.push({ id: "table", name: "Masaya Servis", selected: false })
    this.serviceTypes.push({ id: "delivery", name: "Paket", selected: false })
    this.serviceTypes.push({ id: "self-ordering", name: "Gel - Al", selected: false })
    this.serviceTypes.push({ id: "timed-table", name: "Hazır Masa", selected: false })
    // this.serviceTypes.push({ id: "table", name: "Rezervasyon", selected: false })
  }

}






export type modeValues = 'dinner' | 'news' | 'delivery'