import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserEventsService {

  userEvents = new Subject<'logged-in' | 'logged-out' | 'web-app-logged-in'>()

  constructor() { }
}
