// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  --width: 100%;
}

.segment {
  --padding-end: 2px;
  --padding-start: 2px;
}

.theme-cover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: var(--ion-text-color);
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,aAAA;EACA,4BAAA;EACA,mBAAA;AACF","sourcesContent":[".menu {\n  --width: 100%;\n}\n\n.segment {\n  --padding-end: 2px;\n  --padding-start: 2px;\n}\n\n.theme-cover {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 20px;\n  color: var(--ion-text-color);\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
