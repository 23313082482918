// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segment-area ion-segment-button{
    min-width: auto;
    --color-checked: white;
    --indicator-color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/survey-form/components/spin-selector/spin-selector.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;IACtB,2CAA2C;AAC/C","sourcesContent":[".segment-area ion-segment-button{\n    min-width: auto;\n    --color-checked: white;\n    --indicator-color: var(--ion-color-primary);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
