import { Inject, InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SurveyFormComponent } from "./survey-form.component";
import { SURVEY_CONFIG, SurveyConfig } from "./dto/survey-config.model";
import { SurveyFormService } from "./survey-form.service";
import { IonicModule } from "@ionic/angular";
import { StarSelectorComponent } from './components/star-selector/star-selector.component';
import { SpinSelectorComponent } from './components/spin-selector/spin-selector.component';
import { RadioSelectorComponent } from './components/radio-selector/radio-selector.component';
import { CheckboxSelectorComponent } from './components/checkbox-selector/checkbox-selector.component';
import { SurveyFormRoutingModule } from "./survey-form-routing.module";
import { BranchSelectorComponent } from './components/branch-selector/branch-selector.component';
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  declarations: [SurveyFormComponent, StarSelectorComponent, SpinSelectorComponent, RadioSelectorComponent, CheckboxSelectorComponent, BranchSelectorComponent],
  imports: [CommonModule, IonicModule,SurveyFormRoutingModule, TranslocoModule],
  providers: [SurveyFormService]
})
export class SurveyFormModule {
  constructor(
    @Inject(SURVEY_CONFIG) private config: SurveyConfig,
  ) {
    console.log("", config);
    if(!config.orwiService) throw new Error("Please set config for Module with forRoot");
  }

  static forRoot(config: SurveyConfig): ModuleWithProviders<SurveyFormModule> {
    return {
      ngModule: SurveyFormModule,
      providers: [{ provide: SURVEY_CONFIG, useValue: config }],
    };
  }
}
