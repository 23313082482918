// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-caption {
    margin: 10px 0;
}

.survey-item {
    margin: 10px 0;
    width: 100%;
}


.item-caption {
    margin: 30px 0;
}


.transition {
    transition: all 100ms;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/survey-form/survey-form.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;;AAGA;IACI,cAAc;AAClB;;;AAGA;IACI,qBAAqB;AACzB","sourcesContent":[".survey-caption {\n    margin: 10px 0;\n}\n\n.survey-item {\n    margin: 10px 0;\n    width: 100%;\n}\n\n\n.item-caption {\n    margin: 30px 0;\n}\n\n\n.transition {\n    transition: all 100ms;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
