import { Inject, Injectable } from "@angular/core";
import {
  AlertController,
  ModalController,
  ModalOptions,
  ToastController,
  ToastOptions,
} from "@ionic/angular";
import { SurveyAnswer, SurveySchema } from "./dto/survey-model";
import {
  SURVEY_CONFIG,
  SURVEY_URLS,
  SurveyConfig,
  SurveyOrwiService,
} from "./dto/survey-config.model";
import { SurveyFormComponent } from "./survey-form.component";
import { Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class SurveyFormService {
  private orwiService: SurveyOrwiService;
  private token: any;
  survey: SurveySchema;
  constructor(
    private modalController: ModalController,
    @Inject(SURVEY_CONFIG) private config: SurveyConfig,
    private router: Router,
    private toastController: ToastController,
    private alertController: AlertController,
    private translocoService: TranslocoService
  ) {
    this.orwiService = config.orwiService();
    this.token = config.token;

    console.log(this.orwiService, this.token);
    if (!this.orwiService)
      throw new Error("Please set config for Module with forRoot");
  }

  async open(
    surveyForm: SurveySchema,
    popUpOptions: Omit<Partial<ModalOptions>, "component"> = {}
  ) {
    this.survey = surveyForm;
    // this.router.navigate(['/survey/', surveyForm.storeId]);

    return this.openModal({
      component: SurveyFormComponent,
      componentProps: {
        surveyForm,
      },
      ...popUpOptions,
    });
    if (surveyForm.tigger == "link") {
      return surveyForm;
    }

    if (surveyForm.tigger == "popup") {
      return this.openModal({
        component: SurveyFormComponent,
        componentProps: {
          surveyForm,
        },
        ...popUpOptions,
      });
    }
  }

  private async openModal(options: ModalOptions) {
    console.log("", options);
    const modalRef = await this.modalController.create(options);
    await modalRef.present();

    return modalRef;
  }

  async saveSurveyAnswer(surveyForm: SurveyAnswer): Promise<any> {
    if (!this.orwiService)
      throw new Error("Please set config for Module with forRoot");
    try {
      return new Promise((resolve, reject) => {
        return this.orwiService
          .serviceRequestPromise(
            SURVEY_URLS.saveSurveyAnswer,
            surveyForm,
            this.token
          )
          .then(({response}) => {
            console.log(response)
            if (response) {
              return resolve(response.message);
            }

            if (response.error) {
              return reject(response.error.desc);
            }
          });
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getSurveys(storeId: string): Promise<SurveySchema[]> {
    try {
      const { response } = await this.orwiService.serviceRequestPromise(
        "/api/survey/getSurveySchemas",
        {
          storeId,
        },
        this.token ?? "deneme"
      );

      return (response as SurveySchema[]).filter((el) => el.isActive);
    } catch (error) {
      return [];
    }
  }

  async getActiveSurveySchema(storeId: string): Promise<SurveySchema> {
    try {
      const { response } = await this.orwiService.serviceRequestPromise(
        "/api/survey/getActiveSurveySchema",
        {
          storeId,
        },
        this.token
      );

      return response;
    } catch (error) {
      return new SurveySchema();
    }
  }

  async openAlert(message: string) {
    console.log(message);
    const alertRef = await this.alertController.create({
      header: this.translocoService.translate(
        "ANKETE KATILDIĞINIZ İÇİN TEŞEKKÜR EDERİZ"
      ),
      message: message,
      buttons: [
        {
          text: this.translocoService.translate("Ok"),
        },
      ],
    });

    await alertRef.present();

    return alertRef.onDidDismiss();
  }

  async getChildStores(storeId: string): Promise<any[]> {
    try {
      const { response } = await this.orwiService.serviceRequestPromise(
        "/api/store/getChildStores",
        {
          parentId: storeId,
        },
        this.token
      );

      return response;
    } catch (error) {
      return [];
    }
  }

  shakeElement(el) {
    if (!el) return;
    let wiggletime = 100;
    el.classList.add("transition");
    el.style.marginLeft = "20px";

    setTimeout(function () {
      el.style.marginLeft = "-20px";
      setTimeout(function () {
        el.style.marginLeft = "10px";
        setTimeout(function () {
          el.style.marginLeft = "-10px";
          setTimeout(function () {
            el.style.marginLeft = "0px";
          }, wiggletime);
        }, wiggletime);
      }, wiggletime);
    }, wiggletime);

    return true;
  }

  async toast(
    header: string,
    message: string,
    position: "top" | "middle" | "bottom",
    color: "danger" | "warning" | "success",
    duration: number = 2000
  ) {
    let config: ToastOptions = {
      header: header,
      message: message,
      position: position,
      color: color,
      animated: true,
      cssClass: "toast",
      duration,
    };

    const toast = await this.toastController.create(config);
    toast.present();
  }
}
