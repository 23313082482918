import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { OrwiService } from '../orwi.service';
import { LocationService } from './location.service';
import { OrwiAddress, poligon, UserLocation } from '../dto/orwi-address';
import { geolocation } from '../dto/orwi-user';
import { UserService } from '../user/user.service';
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  countryRestrict = { 'country': 'tr' };
  address: OrwiAddress = new OrwiAddress()
  route: string = ""
  streetNumber: string = ""
  googleWorking = false
  constructor(
    private us: UserService,
    private ls: LocationService,
    private os: OrwiService,
    private http: HttpClient,
    private alertController: AlertController,
    public glb: GlobalService) { }


  //seçilen adresin işletme hizmet alanı içinde olup olmadığını kontrol eder.
  async checkSelectedPoint(location, checkPoly) {

    if (location == undefined) {
      return;
    }



    if (checkPoly == false) {
      let msp = await this.ls.getReverseGeoCode(location.lat(), location.lng())
      this.setAddresstoModel(msp[0])
      return this.address
    }

    let polygonList: poligon[] = this.getPolygonList()
    this.glb.consolelog('polygonList', polygonList)


    for (let index = 0; index < polygonList.length; index++) {
      const element = polygonList[index];
      var polygon = new google.maps.Polygon({ paths: element.polyline });

      let locationStatus = google.maps.geometry.poly.containsLocation(location, polygon) // Şimdilik Kapattım hizmet sınır kontrol

      if (locationStatus == true) {
        const alert = await this.alertController.create({
          header: 'Orwi',
          message: 'Seçmiş Olduğunuz Adres ' + element.name + ' Sınırları İçinde.',
          buttons: ['Tamam']
        });
        // await alert.present();
         let msp = await this.ls.getReverseGeoCode(location.lat(), location.lng())
         this.setAddresstoModel(msp[0])
        return await this.address
      }
    }

    let dist = this.ls.distance(this.ls.currentLocation.latitude, this.ls.currentLocation.longitude, location.lat(), location.lng(), "K")
    this.glb.consolelog(dist)
    const alert = await this.alertController.create({
      header: 'Orwi',
      message: 'Seçmiş Olduğunuz Adres Hizmet Alanı Dışında.',
      buttons: ['Tamam']
    });
    await alert.present();
    return

  }

  //seçilen adrese ait adres detayını verir.
  // getPlaceDetail(lat, lng) {

  //   return new Promise((resolve, reject) => {


  //     this.googleWorking = true

  //     //this.glb.showLoading("")

  //     var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c"
  //     this.http.get(apiUrl).subscribe((response: any) => {
  //       this.setAddresstoModel(response.results[0])
  //       this.glb.consolelog("SelectedAddress", this.address)
  //       this.glb.closeLoading()
  //       this.googleWorking = false
  //       resolve("")
  //     }, e => {
  //       this.googleWorking = false
  //     });

  //   })



  // }

  parseCoords(value) {
    return parseFloat(value)
  }

  //seçilen adresi modele doldurur.
  setAddresstoModel(places) {
    this.glb.consolelog('api selected Address', places)
    this.address.address = places.formatted_address
    this.address.geoLocation.approve = true;
    this.address.geoLocation.latitude = places.geometry.location.lat
    this.address.geoLocation.longitude = places.geometry.location.lng
    for (let index = 0; index < places.address_components.length; index++) {
      const addressType = places.address_components[index].types[0];
      if (addressType == "route") {
        this.route = places.address_components[index].long_name
      }
      else if (addressType == "administrative_area_level_4") {
        //semt
        this.address.district = places.address_components[index].long_name
      }
      else if (addressType == "administrative_area_level_2") {
        //ilçe
        this.address.town = places.address_components[index].long_name
      }
      else if (addressType == "administrative_area_level_1") {
        //il
        this.address.city = places.address_components[index].long_name
      }
      else if (addressType == "country") {
        //il
        this.address.country = places.address_components[index].long_name
      }
      else if (addressType == "postal_code") {
        //posta kodu
      }
      else if (addressType == "street_number") {
        this.streetNumber = places.address_components[index].long_name
      }
    }
  }

  getPolygonList() {
    let polyList: poligon[] = []
    let p1: poligon = new poligon()
    p1.minAmount = 50
    p1.name = "1.Poligon (Urla)"
    p1.polyline.push(
      { lat: 38.427913, lng: 26.71968 },
      { lat: 38.404241, lng: 26.674361 },
      { lat: 38.312708, lng: 26.7032 },
      { lat: 38.30732, lng: 26.59883 },
      { lat: 38.208111, lng: 26.662002 },
      { lat: 38.223217, lng: 26.760879 },
      { lat: 38.317018, lng: 26.977859 },
      { lat: 38.376255, lng: 26.880355 },
      { lat: 38.351489, lng: 26.791091 }
    )

    let p2: poligon = new poligon()
    p2.minAmount = 40
    p2.name = "2.Poligon (Alsancak)"
    p2.polyline.push(
      { lat: 38.442122, lng: 27.143155 },
      { lat: 38.418791, lng: 27.126504 },
      { lat: 38.406753, lng: 27.157574 },
      { lat: 38.435131, lng: 27.170105 }
    )

    let p3: poligon = new poligon()
    p3.minAmount = 70
    p3.name = "3.Poligon (Bornova)"
    p3.polyline.push(
      { lat: 38.460386, lng: 27.200391 },
      { lat: 38.461058, lng: 27.251116 },
      { lat: 38.442237, lng: 27.250859 },
      { lat: 38.442237, lng: 27.198245 }
    )

    polyList.push(p1)
    polyList.push(p2)
    polyList.push(p3)
    return polyList

  }

  getPhoneCodes() {
    //return this.cs.readDocumentFromMongo("orwi-mockup/phone-codes")
  }

  checkAddress(addressId, storeId, geo: geolocation) {


    let payload =
    {
      addressId: addressId,
      storeId: storeId,
      geo: geo
    }

    this.glb.consolelog(payload)

    return this.os.serviceRequestPromise("/api/polygon/controlPolygon", payload, this.us.token)
  }



  // getReverseGeoCode(lat, lng) {
  //   this.glb.consolelog("getReverseGeoCode", lat, lng)
  //   return new Promise((resolve, reject) => {
  //     let ul: UserLocation[] = []
  //     let locs = localStorage.getItem("locations")
  //     if (locs) {
  //       let ul: UserLocation[] = JSON.parse(locs)
  //       let finded = ul.find(o => o.lat == lat && o.lng == lng)
  //       if (finded) {
  //         console.log("getReverseGeoCode Resolved Local", finded.name)
  //         resolve(finded.name)
  //         return
  //       }
  //     }

  //     var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c"
  //     this.http.get(apiUrl).subscribe((response: any) => {

  //       let xul: UserLocation = {
  //         lat: lat,
  //         lng: lng,
  //         name: response.results
  //       }

  //       ul.push(xul)
  //       localStorage.setItem("locations", JSON.stringify(ul))
  //       console.log("getReverseGeoCode Resolved API", response.result)
  //       resolve(response.results)




  //     }, e => {
  //       reject("error")
  //     });







  //   })




  // }




}


