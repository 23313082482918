import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SurveyFormService } from '../../survey-form.service';

@Component({
  selector: 'app-branch-selector',
  templateUrl: './branch-selector.component.html',
  styleUrls: ['./branch-selector.component.css']
})
export class BranchSelectorComponent {

  constructor(private surveyService: SurveyFormService) { }

  @Input({alias: 'storeId'}) set _storeId(e) {
    this.getStores(e);
  } 

  @Output() onBranchSelect = new EventEmitter<any>();

  stores = [];

  getStores(e) {
    this.surveyService.getChildStores(e).then(stores => {
      this.stores = stores;
    })
  }

  selectStore(e: any) {
    console.log(e);
    this.onBranchSelect.emit(e.detail.value);
  }
}
