// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  transition: width 2s;
  margin-top: 2.5%;
  font-size: 12px;
  background-color: var(--ion-background-color);
  height: 95%;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  padding: 10px;
  border-radius: 20px 20px 0px 0px;
  overflow: scroll;
  color: var(--ion-text-color);
}

input {
  border-bottom: 1px solid;
  border-radius: 0px;
  padding-left: 0px;
  font-size: 16px;
}

textarea {
  border: 0px;
  border-bottom: 1px solid;
  border-radius: 0px;
  padding-left: 0px;
  font-size: 16px;
  width: 100%;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delivery/address-form/address-form.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,6CAAA;EACA,WAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,gCAAA;EACA,gBAAA;EACA,4BAAA;AACF;;AAEA;EACE,wBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,wBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;AACF","sourcesContent":[".form {\n  transition: width 2s;\n  margin-top: 2.5%;\n  font-size: 12px;\n  background-color: var(--ion-background-color);\n  height: 95%;\n  width: 95%;\n  margin-left: 2.5%;\n  margin-right: 2.5%;\n  padding: 10px;\n  border-radius: 20px 20px 0px 0px;\n  overflow: scroll;\n  color: var(--ion-text-color);\n}\n\ninput {\n  border-bottom: 1px solid;\n  border-radius: 0px;\n  padding-left: 0px;\n  font-size: 16px;\n}\n\ntextarea {\n  border: 0px;\n  border-bottom: 1px solid;\n  border-radius: 0px;\n  padding-left: 0px;\n  font-size: 16px;\n  width: 100%;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
