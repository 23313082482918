import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ToastController } from '@ionic/angular';
import { SoketPushDeeplinkRoutingService } from './soket-push-deeplink-routing.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {


  message = '';
  messages = [];
  currentUser = '';
  connected = false

  constructor(
    public socket: Socket,
    private glb: GlobalService,
    //private spdr: SoketPushDeeplinkRoutingService,
    private toastCtrl: ToastController) {




  }


  initEvents(userToken) {

    this.socket.fromEvent("connect").subscribe(o => {
      this.glb.consolelog("scoket.connect", o)
      this.connected = true
    })

    this.socket.fromEvent("welcome").subscribe(o => {
      this.glb.consolelog(o)
      this.socket.emit("login", userToken)
    })



  }

  async showToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
}
