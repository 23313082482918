import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-star-selector",
  templateUrl: "./star-selector.component.html",
  styleUrls: ["./star-selector.component.css"],
})
export class StarSelectorComponent {
  @Input({alias: 'stars'}) set _stars(e) {
    this.stars = e.map((e) => ({ text: e, selected: false }));
  }

  stars = [];

  get unChecked() {
    return this.stars.filter((e) => !e.selected);
  }

  get checked() {
    return this.stars.filter(e => e.selected);
  }

  @Output() onStarSelected = new EventEmitter<any>();

  selectStar(starIndex) {
    console.log('Selected Stars',starIndex);
    this.stars.forEach((e) => {e.selected = false});
    for (let index = 0; index <= starIndex; index++) {
      this.stars[index].selected = true;
    }

    console.log(this.stars[starIndex]);
    this.onStarSelected.emit(this.stars[starIndex].text);
  }
}
