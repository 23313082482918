import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { GlobalService } from "../global.service";
import { SoketPushDeeplinkRoutingService } from "./soket-push-deeplink-routing.service";
import { Capacitor } from "@capacitor/core";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: "root",
})
export class PushService {
  //Rating
  //Kazı Kazan
  //

  constructor(
    private snt: DomSanitizer,
    private glb: GlobalService,
    private spdr: SoketPushDeeplinkRoutingService,
    private userService : UserService
  ) {}

  initPush(): Promise<string> {
    if(!Capacitor.isNativePlatform()) return Promise.resolve('not supported')
    return new Promise((resolve, reject) => {
      PushNotifications.checkPermissions().then(async (result) => {
        if (result.receive == "prompt") {
          result = await PushNotifications.requestPermissions();
        }

        if (result.receive == "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          /* PushNotifications.listChannels().then(o => {
        }) */
        }
        if (result.receive == "denied") {
          this.glb.PushToken = "no permission";
          resolve("");
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token: Token) => {
        this.glb.consolelog("push register token", token.value);
        //alert('Push registration success, token: ' + token.value);
        this.glb.PushToken = token.value;
        // this.userService.setPushToken()
        resolve(token.value);
        //this.us.setPushToken()
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        this.glb.consolelog("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          this.parsePushData(notification);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          this.parsePushData(notification.notification);
        }
      );
    });
  }

  parsePushData(notification) {
    let orwipush = JSON.parse(notification.data.orwi);
    if (orwipush.action == "folio-received") {
      this.spdr.route({ type: "folio-received", pushData: orwipush.folioId });
    } else if (orwipush.action == "folio-closed") {
      this.spdr.route({ type: "folio-closed", pushData: orwipush.folioId });
    } else if (orwipush.action == "folio-changed") {
      //this.spdr.route({ type: "folio-changed", pushData: orwipush.folioId })
    } else if (orwipush.action == "scratch-won") {
      this.spdr.route({ type: "new-scratch", pushData: orwipush.id });
    } else if ((orwipush.action == "redirect-url")) {
      this.link(orwipush.url);

      this.spdr.route({ type: "redirect-url", pushData: orwipush.url });
    }

    //actions: folio-received | folio-closed | folio-changed | user-point-changed | show-scratch
    //folio-recived, closed, changed: folioId
    //user-point-changed
    //new-scratch: coupon-id
    //new-coupon: coupon-id

    /*    if (this.glb.applicationReady) {
 
         let orwipush = JSON.parse(notification.data.orwi)
        
         if (orwipush.action == "folio-received") {
           this.glb.consolelog("*************************")
           this.glb.consolelog(orwipush)
           this.glb.consolelog("*************************")
 
           let se: SocketEvent = new SocketEvent("folio-received", orwipush.folioId)
           this.oe.socketEvents.next(se)
 
         } else if (orwipush.action == "folio-closed") {
 
           let se: SocketEvent = new SocketEvent("folio-closed", orwipush.folioId)
           this.oe.socketEvents.next(se)
 
         }
 
       } */

    /* if (this.userService.user) {
      if (notification.data.orwi) {
        let orwipush = JSON.parse(notification.data.orwi)
        if (orwipush.action == "qr-payment") {
          this.storeService.storeCheckIn(orwipush.folioId).then(o => {
            this.nav.navigateRoot(["/payment-preview", { id: "qrPayment" }])
          })
        } else {
          if (orwipush.url) {
            this.glb.orwiContent = orwipush
            this.nav.navigateForward("/browser")
          }
        }
      }
    } */
  }

  link(url) {
    //let url =  this.snt.bypassSecurityTrustResourceUrl("instagram://user?username=orwi.app")
    //document.location.href = url
    window.open(url, "_system");
  }
}
