import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: "survey/:storeID",
    loadChildren: () => import('./modules/survey-form/survey-form.module').then(m => m.SurveyFormModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/home/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'otp-request',
    loadChildren: () => import('./pages/login/otp-request/otp-request.module').then(m => m.OtpRequestPageModule)
  },
  {
    path: 'otp-verify',
    loadChildren: () => import('./pages/login/otp-verify/otp-verify.module').then(m => m.OtpVerifyPageModule)
  },
  {
    path: 'select-country',
    loadChildren: () => import('./pages/login/select-country/select-country.module').then(m => m.SelectCountryPageModule)
  },
  {
    path: 'scan-qr',
    loadChildren: () => import('./pages/qr-code/scan-qr/scan-qr.module').then(m => m.ScanQrPageModule)
  },
  {
    path: 'app-menu',
    loadChildren: () => import('./pages/app-menu/app-menu.module').then(m => m.AppMenuPageModule)
  },
  {
    path: 'select-cat',
    loadChildren: () => import('./pages/home/select-cat/select-cat.module').then(m => m.SelectCatPageModule)
  },
  {
    path: 'filters',
    loadChildren: () => import('./pages/home/filters/filters.module').then(m => m.FiltersPageModule)
  },
  {
    path: 'my-code',
    loadChildren: () => import('./pages/qr-code/my-code/my-code.module').then(m => m.MyCodePageModule)
  },
  {
    path: 'my-reservations',
    loadChildren: () => import('./pages/reservations/my-reservations/my-reservations.module').then( m => m.MyReservationsPageModule)
  },
  {
    path: 'store-home',
    loadChildren: () => import('./pages/store/store-home/store-home.module').then(m => m.StoreHomePageModule)
  },
  {
    path: 'menu-home',
    loadChildren: () => import('./pages/menu/menu-home/menu-home.module').then(m => m.MenuHomePageModule)
  },
  {
    path: 'address-list',
    loadChildren: () => import('./pages/delivery/address-list/address-list.module').then(m => m.AddressListPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/menu/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },
  {
    path: 'basket-details',
    loadChildren: () => import('./pages/menu/basket-details/basket-details.module').then(m => m.BasketDetailsPageModule)
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./pages/scheduler/scheduler.module').then(m => m.SchedulerPageModule)
  },
  {
    path: 'in-store-home',
    loadChildren: () => import('./pages/store/in-store-home/in-store-home.module').then(m => m.InStoreHomePageModule)
  },
  {
    path: 'my-gifts',
    loadChildren: () => import('./pages/store/my-gifts/my-gifts.module').then(m => m.MyGiftsPageModule)
  },
  {
    path: 'scan-qr-web',
    loadChildren: () => import('./web/pages/qr-code/scan-qr-web/scan-qr-web.module').then(m => m.ScanQrWebPageModule)
  },
  {
    path: 'credit-card-form',
    loadChildren: () => import('./payment/pages/credit-card-form/credit-card-form.module').then(m => m.CreditCardFormPageModule)
  },
  {
    path: 'my-cards',
    loadChildren: () => import('./payment/pages/my-cards/my-cards.module').then(m => m.MyCardsPageModule)
  },
  {
    path: 'card-payment',
    loadChildren: () => import('./payment/pages/card-payment/card-payment.module').then(m => m.CardPaymentPageModule)
  },
  {
    path: 'delivery-payment',
    loadChildren: () => import('./payment/pages/delivery-payment/delivery-payment.module').then(m => m.DeliveryPaymentPageModule)
  },
  {
    path: 'payment-result',
    loadChildren: () => import('./payment/pages/payment-result/payment-result.module').then(m => m.PaymentResultPageModule)
  },
  {
    path: 'reservation-home',
    loadChildren: () => import('./pages/reservations/reservation-home/reservation-home.module').then(m => m.ReservationHomePageModule)
  },
  {
    path: 'card-list',
    loadChildren: () => import('./payment/pages/card-list/card-list.module').then(m => m.CardListPageModule)
  },
  {
    path: 'orwi-wallet',
    loadChildren: () => import('./payment/pages/orwi-wallet/orwi-wallet.module').then( m => m.OrwiWalletPageModule)
  },
  {
    path: 'profile-home',
    loadChildren: () => import('./pages/profile/profile-home/profile-home.module').then(m => m.ProfileHomePageModule)
  },
  {
    path: 'browser',
    loadChildren: () => import('./pages/helpers/browser/browser.module').then(m => m.BrowserPageModule)
  },
  {
    path: 'old-orders',
    loadChildren: () => import('./pages/profile/old-orders/old-orders.module').then(m => m.OldOrdersPageModule)
  },
  {
    path: 'order-detail-delivery',
    loadChildren: () => import('./pages/profile/order-detail-delivery/order-detail-delivery.module').then(m => m.OrderDetailDeliveryPageModule)
  },
  {
    path: 'order-detail-other',
    loadChildren: () => import('./pages/profile/order-detail-other/order-detail-other.module').then(m => m.OrderDetailOtherPageModule)
  },
  {
    path: 'feedback-home',
    loadChildren: () => import('./pages/feedback/feedback-home/feedback-home.module').then(m => m.FeedbackHomePageModule)
  },
  {
    path: 'feedback-detail',
    loadChildren: () => import('./pages/feedback/feedback-detail/feedback-detail.module').then(m => m.FeedbackDetailPageModule)
  },
  {
    path: 'payment3d',
    loadChildren: () => import('./payment/pages/payment3d/payment3d.module').then(m => m.Payment3dPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/profile/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/profile/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/home/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/orwi/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'about-orwi',
    loadChildren: () => import('./pages/orwi/about-orwi/about-orwi.module').then(m => m.AboutOrwiPageModule)
  },
  {
    path: 'rating',
    loadChildren: () => import('./pages/store/rating/rating.module').then(m => m.RatingPageModule)
  },

  {
    path: 'redirect',
    loadChildren: () => import('./pages/helpers/redirect/redirect.module').then(m => m.RedirectPageModule)
  },
  {
    path: 'favorite-restaurants',
    loadChildren: () => import('./pages/favorite-restaurants/favorite-restaurants.module').then( m => m.FavoriteRestaurantsPageModule)
  },
  {
    path: 'no-internet',
    loadChildren: () => import('./helpers/no-internet/no-internet.module').then(m => m.NoInternetPageModule)
  },

  {
    path: 'coupon-detail',
    loadChildren: () => import('./pages/store/coupon-detail/coupon-detail.module').then( m => m.CouponDetailPageModule)
  },

  {
    path: 'payment-result-tip',
    loadChildren: () => import('./payment/pages/payment-result-tip/payment-result-tip.module').then( m => m.PaymentResultTipPageModule)
  },

  {
    path: 'select-card-tip',
    loadChildren: () => import('./payment/pages/select-card-tip/select-card-tip.module').then( m => m.SelectCardTipPageModule)
  },

  {
    path: 'text-main',
    loadChildren: () => import('./pages/text-main/text-main.module').then( m => m.TextMainPageModule)
  },
  {
    path: 'text/:id',
    loadChildren: () => import('./pages/text/text.module').then( m => m.TextPageModule)
  },
 
  {
    path: 'invoice-list',
    loadChildren: () => import('./pages/invoice/invoice-list/invoice-list.module').then( m => m.InvoiceListPageModule)
  },
  {
    path: 'invoice-form',
    loadChildren: () => import('./pages/invoice/invoice-form/invoice-form.module').then( m => m.InvoiceFormPageModule)
  },
  {
    path: 'store-category-list',
    loadChildren: () => import('./pages/store/store-category-list/store-category-list.module').then( m => m.StoreCategoryListPageModule)
  },
 
  { path: '**', redirectTo: '/redirect' },
  
 
  



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
