// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-rating {
    display: flex;
    gap: 10px;
    width: 100%;
}

.star-rating ion-icon {
    flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/survey-form/components/star-selector/star-selector.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,WAAW;AACf;;AAEA;IACI,OAAO;AACX","sourcesContent":[".star-rating {\n    display: flex;\n    gap: 10px;\n    width: 100%;\n}\n\n.star-rating ion-icon {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
