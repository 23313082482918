import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Coupon } from 'src/app/services/dto/orwi-store';

export interface ScratchState {
    visible: boolean
    coupon: Coupon
    giftImage: string
    name: string
    storeName: string,
    desc: string
}

export function createInitialState(): ScratchState {
    return {
        visible: false,
        coupon: null,
        giftImage: null,
        name: null,
        storeName: null,
        desc: null
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orwi-Scratch' })
export class ScratchStore extends Store<ScratchState> {

    constructor() {
        super(createInitialState());
    }

}

