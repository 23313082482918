import { Injectable } from '@angular/core';
import { Folio } from '../dto/orwi-folio';
import { Coupon } from '../dto/orwi-store';
import { FolioService } from '../folio/folio.service';
import { OrwiService } from '../orwi.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private orwi: OrwiService, private us: UserService, private fs: FolioService) { }


  loadCoupon(id) {
    return new Promise((resolve, reject) => {
      this.orwi.serviceRequestPromise("/api/coupon/loadCoupon", { id: id }, this.us.token).then((o: any) => {
        resolve(o)
      }, e => {
        reject(undefined)
      })
    })
  }

  getUserCoupons() {
    return new Promise((resolve, reject) => {
      this.orwi.serviceRequestPromise("/api/coupon/getUserCoupons", {}, this.us.token).then((o: any) => {
        if (o.response) {
          this.us.user.coupons = o.response
          resolve(this.us.user.coupons)
        }
      }, e => {
        reject(undefined)
      })
    })
  }

  useCoupon(coupon: Coupon, folio: Folio): Promise<Folio> {
    let request = { id: coupon.id, folioId: folio.id, userId: this.us.user.id }
    return new Promise((resolve, reject) => {
      this.orwi.serviceRequestPromise("/api/coupon/useCouponForPOS", request, this.us.token).then((o: any) => {
        if (o.response) {
          resolve(o.response)
        }
      }, e => {
        reject(undefined)
      })
    })
  }

  couponImage(id) {
    return new Promise((resolve, reject) => {
      this.orwi.serviceRequestPromise("/api/image/coupon", { id: id }, this.us.token).then((o: any) => {
        if (o.response) {
          console.log(o.response)
          resolve(o.response)
        }
      }, e => {
        reject(undefined)
      })
    })

  }


}
