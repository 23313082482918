import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';


import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
const config: SocketIoConfig = { url: 'https://gandalf.orwi.app:50151', options: {} };
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SurveyFormModule } from './modules/survey-form/survey-form.module';
import { OrwiService } from './services/orwi.service';
@NgModule({
    declarations: [AppComponent],
    imports: [
        BackButtonDisableModule.forRoot(),
        BrowserModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true
        }),
        AppRoutingModule,
        HttpClientModule,
        TranslocoRootModule,
        SocketIoModule.forRoot(config),
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        SurveyFormModule.forRoot({
            token: JSON.parse(localStorage.getItem("userInfo"))?.token,
            orwiService: () => {
                return new OrwiService();
            }
        })
    ],
    providers: [
        {
            provide: IonRouterOutlet,
            useValue: {
                nativeEl: ""
            }
        },
        StatusBar,
        SplashScreen,       
        OpenNativeSettings,
        SocialSharing,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {


 
}

