import { ComponentRef } from '@angular/core';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrwiContactComponent } from 'src/app/components/home/orwi-contact/orwi-contact.component';
import { Coupon } from 'src/app/services/dto/orwi-store';
import { GlobalService } from 'src/app/services/global.service';
import { CouponService } from 'src/app/services/store/coupon.service';
import { OrwiScratchComponent } from '../orwi-scratch.component';

import { ScratchQuery } from './scratch.query';
import { ScratchStore } from './scratch.store';

@Injectable({ providedIn: 'root' })
export class ScratchService {


  scratchLoaded = new BehaviorSubject("")
  component: ComponentRef<OrwiScratchComponent>

  constructor(
    private app: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private cs: CouponService,
    private glb: GlobalService,
    private scratchStore: ScratchStore, private scratchQuery: ScratchQuery,
  ) {


    this.scratchQuery.scratchVisible$.subscribe(o => {
      if (o) {
        this.addToDom()
      } else {
        this.removeFromDom()
      }

    })

  }

  init(coupon: Coupon) {
    this.glb.showLoading()
    this.cs.couponImage(coupon.id).then((o: any) => {
      this.glb.closeLoading()
      this.scratchStore.update({
        giftImage: o.base64,
        visible: true,
        name: coupon.name,
        desc: coupon.description,
        coupon: coupon
        
      })
    })



  }


  addToDom() {
    if (!this.component) {
      let newNode = document.createElement('div');
      document.getElementById('orwiScratchDiv').appendChild(newNode);
      let resolver = this.componentFactoryResolver.resolveComponentFactory(OrwiScratchComponent);
      let cmp = resolver.create(this.injector, [], newNode)
      this.app.attachView(cmp.hostView);
      this.component = cmp

    }
    setTimeout(() => {
      this.component.instance.show()
    }, 200);


  }

  removeFromDom() {
    if (this.component) {
      this.component.destroy()
      this.component = null
    }
  }






}