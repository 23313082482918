import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterpassEventsService {

  constructor() { }

  paymentEvents = new Subject<'save-folio-error' | 'error' | 'unset' | 'loading' | 'loaded' | 'show-3d' | '3d-error' | 'network-error' | 'success' 
  | 'save-tip-folio-error' | 'network-tip-error' | 'success-tip' | 'error-tip'>()


  masterpassCardLoadingEvents = new Subject<'unset' |
    'card-deleted' |
    'error' |
    'loading' |
    'success'>()

  masterpassEvents = new Subject<
    'unset' |
    'register-card' |
    'link-card' |
    'cancel-register' |
    'cancel-link' |
    'non-masterpass' |
    'non-orwi' |
    'registered' | 'server-error'>()

    masterpassOtpEvents = new Subject<'unset' |
    'validate-otp' |
    'validate-otp-payment' |
    'validate-otp-link'>()
}
