// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orwi-prompt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--orwi-dialog-color);
  width: 95%;
  box-shadow: var(--orwi-shadow);
  border-radius: var(--orwi-radius);
  z-index: 101;
  max-height: 200px;
  max-width: 80%;
  height: auto;
  max-width: 450px;
}

.close {
  font-size: 25px;
  position: absolute;
  right: 8px;
  z-index: 102;
  top: 8px;
  color: var(--ion-color-success-contrast);
}

.orwi-actio-backdrop {
  background-color: rgba(171, 172, 173, 0.6196078431);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  top: 0px;
}

.buttons {
  display: flex;
  position: absolute;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content {
  padding: 10px;
  height: 60%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Montserrat";
  color: var(--ion-text-color);
}

.title {
  width: 100%;
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
  padding: 9px;
  font-weight: bold;
  min-height: 35px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/orwi-prompt/orwi-prompt.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,0CAAA;EACA,UAAA;EACA,8BAAA;EACA,iCAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,QAAA;EACA,wCAAA;AACF;;AAEA;EACE,mDAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,4BAAA;AACF;;AAEA;EACE,WAAA;EACA,0CAAA;EACA,wCAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AACF","sourcesContent":[".orwi-prompt {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto;\n  background-color: var(--orwi-dialog-color);\n  width: 95%;\n  box-shadow: var(--orwi-shadow);\n  border-radius: var(--orwi-radius);\n  z-index: 101;\n  max-height: 200px;\n  max-width: 80%;\n  height: auto;\n  max-width: 450px;\n}\n\n.close {\n  font-size: 25px;\n  position: absolute;\n  right: 8px;\n  z-index: 102;\n  top: 8px;\n  color: var(--ion-color-success-contrast);\n}\n\n.orwi-actio-backdrop {\n  background-color: #abacad9e;\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  z-index: 100;\n  top: 0px;\n}\n\n.buttons {\n  display: flex;\n  position: absolute;\n  bottom: 10px;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.content {\n  padding: 10px;\n  height: 60%;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  font-family: \"Montserrat\";\n  color: var(--ion-text-color);\n}\n\n.title {\n  width: 100%;\n  background-color: var(--ion-color-success);\n  color: var(--ion-color-success-contrast);\n  padding: 9px;\n  font-weight: bold;\n  min-height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
