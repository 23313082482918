import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { SurveyItemScehma } from "../../dto/survey-model";
import { IonCheckbox, IonInput } from "@ionic/angular";

@Component({
  selector: "app-checkbox-selector",
  templateUrl: "./checkbox-selector.component.html",
  styleUrls: ["./checkbox-selector.component.css"],
})
export class CheckboxSelectorComponent {
  @Input({ alias: "item" }) set _item(e) {
    this.item = e;
  }

  @Output() onSelectCheckBox = new EventEmitter<any>();

  @ViewChildren(IonCheckbox) ionCheckBoxes: QueryList<IonCheckbox>;

  @ViewChild("checkInput", { static: false }) checkInput: IonInput;

  selected = [];

  item: SurveyItemScehma;

  updateCheckbox(e: SurveyItemScehma, event: any) {
    if (event.detail.value == "other-checkbox" && event.detail.checked) {
      setTimeout(() => {
        this.checkInput.setFocus();
      }, 200);
    }

    this.updateSelected();
  }

  updateSelected() {
    this.selected = [];
    this.ionCheckBoxes.forEach((e) => {
      if (e.checked && e.value != "other-checkbox") {
        this.selected.push(e.value);
      }

      if (e.checked && e.value == "other-checkbox") {
        if(this.checkInput.value.toString().trim().length > 0) {
          this.selected.push(this.checkInput.value);
        }
      }
    });

    console.log(this.selected);

    this.onSelectCheckBox.emit(this.selected);
  }
}
