export class OrwiAddress {
    id = ""
    name = ""
    phone = ""
    cityId = ""
    city = ""
    townId = ""
    town = ""
    districtId = ""
    district = ""
    address = ""
    direction = ""
    country = ""
    countryId = ""
    userId = ""
    storeId = ""
    deleted = false
    geoLocation :  oaLlocation = new oaLlocation()
    type: 'address' | 'location' = "address"
    building = ""
    flooar = ""
    apartment = ""
}

export class oaLlocation {
    approve : boolean= true
    latitude : number = 0
    longitude : number = 0
}

export class orwiCountry {
    code = ""
    name = ""
    flagEmoji = ""
    dialCode = ""
}

export class orwiCity {
    countryCode = ""
    code = ""
    name = ""

}

export class orwiTown {
    countryCode = ""
    cityCode = ""
    code = ""
    name = ""
}


export class poligon {
    minAmount : number = 0
    name : string = ""
    polyline : poly[] = []
}
export class poly {
    lat
    lng
}


export interface UserLocation {
    lat: number,
    lng: number,
    name: string,
  }

  export interface OrwiLocation {
    lat: number,
    lng: number,
    address: [],
  }