import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-spin-selector',
  templateUrl: './spin-selector.component.html',
  styleUrls: ['./spin-selector.component.css']
})
export class SpinSelectorComponent {

  @Input({alias: 'segments'}) set _segments(e) {
    this.segments = e;
  } 

  @Output() onSegmentSelected = new EventEmitter<any>();

  segments = [];

  segmentChange(e) {
    console.log("", e);

    this.onSegmentSelected.emit(e.detail.value);
  }

}
