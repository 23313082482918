import { Injectable } from "@angular/core";
import { KeyboardInfo, Keyboard } from "@capacitor/keyboard";
import { App, AppState } from "@capacitor/app";
import { ConnectionStatus, Network } from "@capacitor/network";
import { NavController, Platform } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { OrwiService } from "../orwi.service";
import { OrwiCaptcha } from "src/app/helpers/models/captcha";
import { GlobalService } from "../global.service";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root",
})
export class AppService {
  connected = false;

  stateChanged = new Subject<boolean>();
  deeplinkReceived = new Subject<any>();
  keyboardShow = new Subject<any>();
  keyboardHide = new Subject<any>();
  networkChanged = new Subject<any>();
  homePageLoaded: boolean = false;
  deeplinkQrCode = "";

  constructor(
    private plt: Platform,
    private transloco : TranslocoService,
    private nav: NavController,
    private orwiService: OrwiService,
    private glb: GlobalService,
  ) {
    Network.getStatus().then((o) => {
      this.connected = o.connected;
      if (!o.connected) {
        this.nav.navigateForward("no-internet");
      } else {
        // this.nav.navigateRoot('welcome')
      }
    });

    Network.addListener("networkStatusChange", (state: ConnectionStatus) => {
      console.clear();
      console.log("Network Changed", state);
      console.log(location);
      this.networkChanged.next(state);
      this.connected = state.connected;
      const pathname = location.pathname;
      if (!state.connected) {
        this.nav.navigateForward("no-internet", {
          queryParams: {
            redirectUrl: pathname,
          }
        });
      } else {
        const searchParam = new URL(location.href).searchParams;
        const redirectUrl = searchParam.get('redirectUrl');
        if(searchParam.get('redirectUrl')) {
          this.nav.navigateRoot(redirectUrl);
        }
      }
      //  else {
      //   this.nav.navigateRoot('welcome')
      // }
    });

    

    App.addListener("appStateChange", (state: AppState) => {
      //this.glb.consolelog('App state changed. Is active?', state.isActive);
       this.stateChanged.next(state.isActive);
    });

    App.addListener("appUrlOpen", (data: any) => {
      this.deeplinkReceived.next(data.url);
    });

    if (plt.is("capacitor")) {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        this.keyboardShow.next("");
      });

      Keyboard.addListener("keyboardWillHide", () => {
        this.keyboardHide.next("");
      });
    }
  }

  currentLocation = '';

  createCaptcha(): Promise<OrwiCaptcha> {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise("/api/user/CreateCaptcha", {})
        .then((response: any) => {
          if (response.response) {
            return resolve(response.response);
          }

          if (response.error) return reject(response.error);
        });
    });
  }

  verifyCaptcha(id: string, code: string) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise("/api/user/verifyCaptcha", { id, code })
        .then((response: any) => {
          if (response.response) {
            return resolve(response.response);
          }

          if (response.error) {
            this.glb.toast(this.transloco.translate(response.error.code), "", "middle", "success", 500)

            return reject(response.error);
          }
        });
    });
  }
}
