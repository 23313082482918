import { Component, Input, ViewChild } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { OFFER_IMAGES } from "src/app/services/global.service";
import { ModalService } from "src/app/services/modal.service";
import { StoreService } from "src/app/services/store/store.service";
const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

@Component({
  selector: "app-offer-modal",
  templateUrl: "./offer-modal.component.html",
  styleUrls: ["./offer-modal.component.css"],
})

export class OfferModalComponent {
  isOfferSlideNotEnd = true;
  index: number = 0
  @Input() items: any[] = [];
  

  constructor(private modalService: ModalService, private storeService: StoreService, private modalController: ModalController, private nav: NavController) {}

  ngOnInit() {
    console.log(this.items)
  }

  async closeModal() {
    this.modalService.closeModal();
    // this.index = this.index + 1
    // if(this.items[this.index] === undefined){
    //   this.modalService.closeModal();
    //   localStorage.setItem(
    //     OFFER_IMAGES,
    //     JSON.stringify([...this.items.map((el) => el.imageUrl)])
    //   );
    // } else {
      this.storeService.getImages()
    // }
    console.log()
    //   const modal = await this.modalController.create({
    //     component: OfferModalComponent,
    //     cssClass: 'offer-modal',
    //     backdropDismiss:false,
    //     keyboardClose:false,
    //   });
  
    //   await modal.present();
    //   return modal;
  }

  get isButtonDisabled() {
    if (this.items.length == 1) return false;
    return this.isOfferSlideNotEnd;
  }

  viewOffer(item){
    if(item.rootLink !== ''){
      if(item.rootLink.includes('https://')){
          window.open(item.rootLink)
      } else {
        item.rootLink = 'https://' + item.rootLink
          window.open(item.rootLink)
      }
    }
  }

  slideChange(event: any) {
    console.log(event);
    if (event.detail[0]?.isEnd) {
      this.isOfferSlideNotEnd = false;
    }
  }
}
