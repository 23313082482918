import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Product, ProductGroup } from '../services/dto/orwi-product';

export interface MenuState {
    selectedGroup: ProductGroup
    selectedSubGroup: ProductGroup
    products: Product[]
    infiniteProducts: Product[],
    groups: ProductGroup[]
    subGroups: ProductGroup[],
    giftSelected : boolean,
    searchedProducts: Product[],
    searchKeyword : string,
    allGroups: ProductGroup[],
    intersectingGroup: ProductGroup,
    
    
}

export function createInitialState(): MenuState {
    return {
     selectedGroup : null,
     selectedSubGroup: null,
     infiniteProducts: [],
     products: [],
     groups: [],
     subGroups: [],
     giftSelected : false,
     searchedProducts: [],
     searchKeyword: "",
     allGroups: [],
     intersectingGroup: null
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orwi-menu' })
export class MenuStore extends Store<MenuState> {

  constructor() {
    super(createInitialState());
  }

}