import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { SurveyItemScehma } from "../../dto/survey-model";
import { IonInput } from "@ionic/angular";

@Component({
  selector: "app-radio-selector",
  templateUrl: "./radio-selector.component.html",
  styleUrls: ["./radio-selector.component.css"],
})
export class RadioSelectorComponent {
  @Input({ alias: "item" }) set _item(e) {
    this.item = e;
  }

  @Output() onSelectRadio = new EventEmitter<any>();

  @ViewChild("radioInput", { static: false }) radioInput: IonInput;

  item: SurveyItemScehma;

  updateRadioGroup(e: any) {
    console.log(e);
    if (e == "other") {
      setTimeout(() => {
        if (this.radioInput) {
          this.radioInput.setFocus();
          this.radioInput.value ?? this.onSelectRadio.emit(this.radioInput.value);
        }
      }, 200);
    } else {
      this.onSelectRadio.emit(e);
    }
    
  }

  updateRadioData(e: any) {
    console.log("updateRadioData", e);
    if(e.length > 0) {
      this.onSelectRadio.emit(e);
    }
  }
}
