import { ComponentRef, Injectable } from "@angular/core";
import { IonRouterOutlet, ModalController, ModalOptions } from "@ionic/angular";
import { AddressFormComponent } from "../components/delivery/address-form/address-form.component";
import { GoogleMapSelectAddressComponent } from "../components/location/google-map-select-address/google-map-select-address.component";
import { OfferModalComponent } from "../components/offer-modal/offer-modal.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  // *ngIf="folioQuery.showModifiers$ | async"

  infoModal;
  routerOutlet: HTMLIonRouterOutletElement;
  constructor(
    private _routerOutlet: IonRouterOutlet,
    private modalController: ModalController,
  ) {
    this.routerOutlet = _routerOutlet.nativeEl;
  }

  // async openWastages() {
  //   const infoModal = await this.modalController.create({
  //     component: WastageListComponent,
  //     initialBreakpoint: 0.5,
  //     breakpoints: [0, 0.5, 1]
  //   });
  //   await infoModal.present();

  //   infoModal.onDidDismiss().then(o => {
  //     //this.folioStore.update({ show: false })
  //   })
  // }

  // async openFeedBackModal() {
  //   const infoModal = await this.modalController.create({
  //     component: OrwiContactComponent,
  //     showBackdrop: false,
  //     // backdropDismiss: false,
  //      swipeToClose: true,
  //     // cssClass: 'discount-modal',
  //      presentingElement: this.routerOutlet,
  //     // componentProps: {},
  //     //initialBreakpoint: 0.8,
  //     //breakpoints: [0, 0.8, 1]
  //   });
  //   await infoModal.present();

  //   infoModal.onDidDismiss().then(o => {
  //   })
  // }

  async showOfferModal(items: any[] = []) {
    const modal = await this.modalController.create({
      component: OfferModalComponent,
      componentProps: {
        items,
      },
      cssClass: 'offer-modal',
      backdropDismiss:false,
      keyboardClose:false,
    });

    await modal.present();
    return modal;
  }


  async openAddressForm(address) {
    const infoModal = await this.modalController.create({
      component: AddressFormComponent,
      componentProps: { orwiAddress: address },
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
    await infoModal.present();

    infoModal.onDidDismiss().then((o) => {
      //this.folioStore.update({ show: false })
    });
  }

  async openSelectAddressModal(type: string) {
    const infoModal = await this.modalController.create({
      component: GoogleMapSelectAddressComponent,
      componentProps: { type: type },
      cssClass: "google-map-modal",
      animated: true,
    });
    await infoModal.present();

    infoModal.onDidDismiss().then((o) => {
      //this.folioStore.update({ show: false })
    });
  }

  async openModal(options: ModalOptions) {
    const modal = await this.modalController.create({
      animated: true,
      canDismiss: true,
      backdropDismiss: true,
      ...options,
    });
    await modal.present();

    return modal;
  }

  async closeModal(data?: any) {
    return this.modalController.dismiss(data);
  }

  // async openDateRangePickerModal1() {

  //   let calendarData: CalendarState = {
  //     dateFrom: null,
  //     dateTo: null,
  //     scrollPosition: 0,
  //     selectedButtonName: FilterBtnConstants.empthyString
  //   };

  //   const infoModal = await this.modalController.create({
  //     component: DateRangePickerComponent,
  //     showBackdrop: false,
  //      swipeToClose: true,
  //      presentingElement: this.routerOutlet,
  //     componentProps: {
  //       previousState: calendarData
  //     }
  //   });
  //   return infoModal.present();

  // }
}
