import { Component } from "@angular/core";
import {
  SurveyAnswer,
  SurveyItemScehma,
  SurveySchema,
} from "./dto/survey-model";
import { IonInput, NavController } from "@ionic/angular";
import { SurveyFormService } from "./survey-form.service";
import { ActivatedRoute } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-survey-form",
  templateUrl: "./survey-form.component.html",
  styleUrls: ["./survey-form.component.css"],
})
export class SurveyFormComponent {
  formattedItems: {
    categoryName: string;
    items: SurveyItemScehma[];
  }[] = [];
  constructor(
    private surveyService: SurveyFormService,
    private acRoute: ActivatedRoute,
    private nav: NavController,
    private transloco: TranslocoService
  ) {
    acRoute.params.subscribe((params: any) => {
      if (params.storeID) {
        this.getSurveyByStoreId(params.storeID);
      }
    });
  }

  getSurveyByStoreId(storeID: string) {
    this.surveyService.getActiveSurveySchema(storeID).then((response) => {
      if (!response.id) {
        this.surveyService.toast(
          "",
          this.transloco.translate("Survey Not Found"),
          "middle",
          "warning"
        );
        this.nav.navigateRoot("/");
        return;
      }
      if (response) {
        console.log(response);
        this.formattedItems = this.formatByCategory(response);
        this.surveyForm = response;
      }
    });
  }

  formatByCategory(response: SurveySchema) {
    let categories = [
      ...new Set([...response.items.map((item) => item.categoryName)]),
    ];
    let formattedData = categories
      .map((category) => ({
        categoryName: category,
        items: response.items.filter((item) => item.categoryName === category).sort((a,b) => a.sequenceNo > b.sequenceNo ? 1 : -1),
      }))
      .sort((a, b) => (a.categoryName > b.categoryName ? 1 : -1));
    console.log(formattedData);
    return formattedData;
  }

  surveyForm: SurveySchema;

  dynamicForm: Object = {};

  updateFormData(item: SurveyItemScehma, value) {
    // if (item.isMulti) {
    //   if (Array.isArray(this.dynamicForm[item.id])) {
    //     const foundedIndex = this.dynamicForm[item.id].findIndex(
    //       (el) => el == value
    //     );
    //     if (foundedIndex === -1) {
    //       this.dynamicForm[item.id].push(value);
    //     } else {
    //       this.dynamicForm[item.id].splice(foundedIndex, 1);
    //     }
    //   } else {
    //     this.dynamicForm[item.id] = [value];
    //   }
    // } else {
    //   this.dynamicForm[item.id] = value;
    // }
    if (value || (Array.isArray(value) && value.length > 0)) {
      this.dynamicForm[item.id] = value;
    } else {
      delete this.dynamicForm[item.id];
    }
  }

  close() {
    this.nav.navigateRoot("/");
  }

  checkIsValid() {
    const requiredIds = this.surveyForm.items.filter((item) => item.isRequired);
    const requireIDs = [];
    requiredIds.forEach((requiredId) => {
      if (!this.dynamicForm[requiredId.id]) {
        requireIDs.push(requiredId.id);
      }
    });
    if (requireIDs.length > 0) {
      const requiredArea = document.getElementById("question-" + requireIDs[0]);
      requiredArea?.scrollIntoView({
        behavior: "smooth",
      });
      setTimeout(() => {
        this.surveyService?.shakeElement(requiredArea);
      }, 500);

      return requireIDs;
    }

    return [];
  }
  save() {
    if (this.checkIsValid().length > 0) {
      return;
    }

    const surveyAnswer = new SurveyAnswer();
    surveyAnswer.storeId = this.surveyForm.storeId;
    surveyAnswer.surveyId = this.surveyForm.id;
    surveyAnswer.surveyName = this.surveyForm.name;
    surveyAnswer.answers = [];
    for (const [itemId, value] of Object.entries(this.dynamicForm)) {
      const item = this.surveyForm.items.find((el) => el.id == itemId);
      if (Array.isArray(value)) {
        value.forEach((answer) => {
          this.addToForm({
            item,
            itemId,
            surveyAnswer,
            answer,
          });
        });
      } else {
        this.addToForm({
          item,
          itemId,
          surveyAnswer,
          answer: value,
        });
      }
    }

    this.surveyService.saveSurveyAnswer(surveyAnswer).then((response) => {
      // this.surveyService.toast('', 'SuccessFully Saved', 'middle', 'success');
      this.surveyService.openAlert(response).then(() => {
        this.nav.navigateRoot("");
      });
    });
  }

  addToForm({ surveyAnswer, itemId, answer, item }) {
    surveyAnswer.answers.push({
      itemId,
      answer: answer,
      inputType: item.input.inputType,
      itemName: item.name,
      surveyId: this.surveyForm.id,
      surveyName: this.surveyForm.name,
    });
  }

  focusInput(event: any, input: IonInput) {
    input.setFocus();
  }
}
