import { Coupon } from "./orwi-store"

export class User {
    id = ""
    name = ""
    email = ""
    image = ""
    gsm = ""
    status: 'new' | 'registered' | 'deleted' | 'banned'

    dateOfBirth = ""
    gender: 'male' | 'female' | 'undefined'

    orwiPoints: PointInfo[] = []

    action: Action = new Action()

    permissions: notificationPermissons = new notificationPermissons()

    isMaster = false
    isVirtual = false

    geolocation: geolocation = new geolocation()

    coupons: Coupon[] = []

    openCheckIn = ""

    // tipCard = ""
    //tipInformation: Date = new Date()

}

export class OrwiPoint {
    id = ""
    storeId = ""
    storeName = ""
    totalPoint = 0.0
}

export class notificationPermissons {
    push = true
    sms = true
    email = true
    phone = true


}

export class Action {
    id: 'standby' | 'action' = "standby"
    step: 'start' | 'menu' | 'confirm' | 'payment' = "start"
    storeId = ""
    folioId = ""
    timeStamp = new Date()
}

export class PointInfo {
    userId: string = ""
    storeId: string = ""
    storeName: string = ""
    totalWon: number = 0.0
    totalSpent: number = 0.0
    balance: number = 0.0
    lastAction: Date = new Date()
    pointType: "orwi" | "store" | "stamp" = "orwi"
    logo = ""
}

export class OrwiNotification {
    createDate = new Date()
    deleted = false
    detail = ""
    id = ""
    readed = true
    text = ""
    title = ""
    userId = ""
}

export class cardTransactions {
    actionType = ""
    amount = 0
    cardId = ""
    description = ""
    folioId = ""
    id = ""
    status = ""
    transactionDate = new Date()
    userId = ""
}

export class InvoiceAddress {


    id = ""
    taxNo = ""
    taxHome = ""
    companyName = ""
    address = ""
    city = ""
    cityId = ""
    town = ""
    townId = ""
    country = "Turkiye"
    countyId = "tr"
    default = false
    userId = ""
    email = ""
    phone = ""

}

export class geolocation {
    latitude = 0
    longitude = 0
    approve = false
}

/* export class Coupon {
    amount = 0
    count = 1
    couponType: "orwi-point" | "free-product" | 'discount-percent' = "orwi-point"
    createDate = new Date()
    description = ""
    id = ""
    isTemplate = false
    link = ""
    loaded = false
    name = ""
    products: []
    source: "gift-card" | "scratch" = "gift-card"
    storeId = ""
    storeName = ""
    totalPoint = 0
    useCount = 0
    used = false
    userId = "5ecfb843a334af903cd53ae0"
    validThru = new Date()
    logo = ""
} */

export class CreditCard {
    id = ""
    name = ""
    masked_pan = ""
    msisdn = ""
    cardStatus = ""
    uniqueId = ""
    productName = ""
    bankIca = ""
    promtCpin = ""
    isMasterPassMember = ""
    transactions = []
    selected = false
}


export class Transaction3D {
    id = ""
    orderNo = ""
    msisdn = ""
    amount = ""
    transactionDate: Date
    status: Transaction3DStatus = "new"
    responseDesc = ""
}

export interface IFeedBack {
    name: string
    gsm: string
    email: string
    token: string
    subject: string
    message: string
    storeId: string
    qrCode : string

}

export interface IStoreFeedback {


    id: string,
    userId: string,
    status: string,
    feedbackCats: [],
    phone: string,
    storeId: string,
    name: string,
    email: string,
    message: string,
    qrCode : string,

}

export interface IfolioRating {
    folioId: string,
    ratings: [{ id: string, value: 0 }],
    feedback: string
}



export interface StaffInfo {
    userId: string
    userName: string
    position: string
    image: string
}

export type Transaction3DStatus = 'new' | 'success' | 'error'
