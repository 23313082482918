// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#offerSwiper::part(bullet-active) {
  background-color: var(--ion-color-primary);
}

.close-button {
  position: absolute;
  right: -10px;
  top: -15px;
  z-index: 5;
  --border-radius: 100%;
  width: 25px;
  height: 25px;
}

.close-button::part(native) {
  padding: 0px;
}

ion-content::part(scroll) {
  overflow: hidden;
}

.offer-image {
  height: 50%;
  object-fit: contain;
  /* filter: brightness(15%) !important; */
  width: 100%;
}

.bright{
  filter: brightness(100%) !important;
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/offer-modal/offer-modal.component.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,UAAU;EACV,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,wCAAwC;EACxC,WAAW;AACb;;AAEA;EACE,mCAAmC;EACnC,uBAAuB;AACzB","sourcesContent":["#offerSwiper::part(bullet-active) {\n  background-color: var(--ion-color-primary);\n}\n\n.close-button {\n  position: absolute;\n  right: -10px;\n  top: -15px;\n  z-index: 5;\n  --border-radius: 100%;\n  width: 25px;\n  height: 25px;\n}\n\n.close-button::part(native) {\n  padding: 0px;\n}\n\nion-content::part(scroll) {\n  overflow: hidden;\n}\n\n.offer-image {\n  height: 50%;\n  object-fit: contain;\n  /* filter: brightness(15%) !important; */\n  width: 100%;\n}\n\n.bright{\n  filter: brightness(100%) !important;\n  height: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
