import { Injectable } from '@angular/core';
import { Folio, FolioRow, RecordStatus } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { IdGeneratorService } from '../helpers/id-generator.service';
import { OrwiService } from '../orwi.service';
import { UserService } from '../user/user.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipService {

  tipValue: number;
  rating: number;
  message: string;
  staffId = ""
  grandTotalforMasterPass(): any {
    let total: number = this.getFolioTotal("all", true, true)
    total = total * 100
    let totals = total.toFixed(0)
    return totals
  }


  saveFolio() {
    //! clear folio selection and loyalty items
    this.tipFolio.rows.map(o => {
      o.isFree = false
      o.selected = false
    })
    return this._saveFolio()

  }

  _saveFolio(): Promise<boolean> {
    // this.oe.folioEvents.next("loading")
    return new Promise((resolve, reject) => {
      console.clear();
      console.log('SAVE FOLIO', this.tipFolio);
      this.orwiService.serviceRequestPromise("/api/folio/saveFolio", this.tipFolio, this.userService.token).then((o: any) => {

        if (o.response) {
          this.tipFolio = Object.assign(o.response);
          // this.oe.folioEvents.next("save")
          this.glb.consolelog("_saveFolio", this.tipFolio)
          resolve(true)
        } else if (o.error) {
          this.glb.toast(o.error.desc, "", "top", "warning")
          this._refreshFolio()
          resolve(false)
        }

      }, (e: any) => {

        resolve(false)

      })
    })
  }



  _refreshFolio() {
    this.orwiService.serviceRequest("/api/folio/refreshFolio", { id: this.tipFolio.id }, this.userService.token).subscribe((o: any) => {
      if (o.response) {
        this.tipFolio = Object.assign(o.response)
        this.glb.consolelog("_refreshFolio", this.tipFolio)
        if (this.tipFolio.status !== "open") {
          this.tipFolio = new Folio()
        }
      } else if (o.error) {
        this.glb.toast("RefreshFolio", o.error.desc, "top", "warning", 3000)
      }
    }, e => {
    })
  }

  tipFolio = new Folio()

  constructor(
    private ig: IdGeneratorService,
    private userService: UserService, private orwiService: OrwiService, private glb: GlobalService) { }


  _getTipFolio(amount, folioId, staffId) {

    return new Promise((resolve, reject) => {
      if (!this.userService.user) return;



    let fr = new FolioRow()
    fr.id = this.ig.generateMaxi()
    fr.itemID = "tip"
    fr.name = "tip"
    fr.qty = 1
    fr.price = amount
    fr.unitPrice = amount
  


    let payload = {
      storeId: "tip-store",
      tableId: "tip-table",
      source: 'orwi'
    }

    lastValueFrom(this.orwiService.serviceRequest("/api/folio/getFolio", payload, this.userService.token)).then(async (o: any) => {
      if (o.response) {
        this.tipFolio = Object.assign(o.response);
        this.tipFolio.parentID  = folioId
        this.tipFolio.staffId = staffId
        this.tipFolio.rows = []
        this.tipFolio.rows.push(fr)
        await this.saveFolio()
        this.glb.consolelog("_getTipFolio", this.tipFolio);
        return resolve(this.tipFolio);
      } else if (o.error) {
        this.glb.toast(o.error.desc, "TİP", "top", "warning")
        reject(o.error)
      }
    })
    })
  }


  getFolioTotal(rs: RecordStatus = "all", withSpendedPoints = true, withTip = false, withDiscount = true, _folio: Folio = undefined) {
    _folio = _folio ? _folio : this.tipFolio
    let filteredRows = _folio.rows.filter(o => !o.isGift && !o.isPayment && !o.isLoyalty && o.recordStatus != 'deleted')
    if (!withDiscount) {
      filteredRows = filteredRows.filter(o => o.isDiscount == false)
    }
    if (rs !== "all") {
      filteredRows = filteredRows.filter(o => o.recordStatus == rs)
    }
    let total = filteredRows.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0)


    if (withSpendedPoints) {
      total = total - _folio.orwi.spent
    }
    if (withTip) {
      total += _folio.tip
    }
    total = parseFloat(total.toFixed(2))
    return total
  }


  _closeFolio(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/folio/closeFolio", this.tipFolio, this.userService.token).then((o: any) => {

        if (o.response) {
          this.glb.consolelog("_closeFolio", this.tipFolio)
          this.tipFolio = Object.assign(o.response)
          this.userService.user.openCheckIn = ""
          resolve(true)
        } else if (o.error) {
          this.glb.toastButton(o.error.desc, "closeFolio", "top", "warning", false, false, true)
          resolve(false)
        }
      }, (e: any) => {
        reject(e)
      })
    })
  }

}
