import { InjectionToken } from "@angular/core";

export interface SurveyConfig {
  token?: string;
  orwiService: () => SurveyOrwiService;
}

export interface SurveyOrwiService {
  token: string;
  serviceRequestPromise(path, postData, _token): Promise<any>;
}

export const SURVEY_CONFIG = new InjectionToken(
  "This is a configuration object for our survey component"
);

export const SURVEY_URLS = {
    getSurveys: '/api/survey/getSurveySchemas',
    saveSurveyAnswer: '/api/survey/saveSurveyAnswer'
}