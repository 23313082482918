import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { Product } from 'src/app/services/dto/orwi-product';

export interface UIState {
    orwiContactVisible : boolean
    orwiContactClose: boolean
    attachFolio: Folio
    rejectedAttachFolioId : string
    scratch: OrwiScratch
}

export interface OrwiScratch {
  id: string
  visible : boolean
  product: Product
  storeId: string
  storeName: string 
}

export function createInitialState(): UIState {
    return {
       orwiContactVisible: false,
       orwiContactClose: false,
       attachFolio : null,
       rejectedAttachFolioId : "",
       scratch: null

    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orwi-ui' })
export class UIStore extends Store<UIState> {

  constructor() {
    super(createInitialState());
  }

}