import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js'
import { GlobalService } from 'src/app/services/global.service';
import { CouponService } from 'src/app/services/store/coupon.service';
import { OrwiScratchService } from './orwi-scratch.service';
import { ScratchQuery } from './state/scratch.query';
import { ScratchService } from './state/scratch.service';
import { ScratchStore } from './state/scratch.store';

@Component({
  selector: 'app-orwi-scratch',
  templateUrl: './orwi-scratch.component.html',
  styleUrls: ['./orwi-scratch.component.scss'],
})
export class OrwiScratchComponent implements OnInit {


  @Output() closeClick = new EventEmitter<any>()
  @Output() destroyComponent = new EventEmitter<any>()

  iPercent = 0
  constructor(
    public sQ: ScratchQuery,
    private scService: ScratchService,
    private cs: CouponService,
    private glb: GlobalService,
    private scratchStore: ScratchStore,
    private os: OrwiScratchService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.init()


  }

  createNewScratchCard() {

    const scContainer = document.getElementById('js--sc--container')
    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.SPRAY,
      containerWidth: 400,
      containerHeight: 250,
      imageForwardSrc: "http://via.placeholder.com/400x250",
      imageBackgroundSrc: "",
      htmlBackground: "<div id='kedi' style='height: 310px; display: flex; flex-direction: column; justify-content:center; align-items: center;'></div>",
      clearZoneRadius: 50,
      nPoints: 30,
      pointSize: 4,
      callback: () => {


      }
    })
    // Init
    setTimeout(() => {
      let el = document.getElementsByClassName("sc__canvas")[0]
      this.renderer.setStyle(el, "position", "absolute")
      this.renderer.setStyle(el, "top", "55px")

      setTimeout(() => {
        let el2 = document.getElementById('kedi')
        el2.innerHTML = "<img src='/assets/images/coupon-temp.png' /><div style='font-size:16px'>Camelia'dan nefis bir Karamel Latte Kazandın</div>"
      }, 500);

    }, 1);
    sc.init().then(function () {
      let percent = sc.getPercent().toFixed(2)

    })

  }

  init() {

    var that = this;
    const background = this.scratchStore.getValue().giftImage


    const scContainer = document.getElementById('js--sc--container')
    const scInfos = document.querySelector('.sc__infos');
    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.SPRAY,
      containerWidth: 400,
      containerHeight: 250,

      imageForwardSrc: this.glb.giftCardImage,
      imageBackgroundSrc: background,
      htmlBackground: '',
      clearZoneRadius: 40,
      nPoints: 30,
      pointSize: 4,
      callback: function () {
        //alert('Now the window will reload !')
        //window.location.reload()
        //this.iPercent = 100
        //scInfos.innerHTML =  '100%';


        that.glb.showLoading()
        that.cs.loadCoupon(that.scratchStore.getValue().coupon.id).then(o => {

          that.glb.closeLoading()

          scInfos.innerHTML = `
          <div>
          <b>
          ${that.scratchStore.getValue().name}
          </b>
          </div>
          <div>
          ${that.scratchStore.getValue().desc}
          </div>
          `

          that.cs.getUserCoupons().then(o => {
            that.scService.scratchLoaded.next("")
          })
        })


      }
    })

    // Init
    sc.init().then(() => {
      sc.canvas.addEventListener('scratch.move', () => {
        //let percent = sc.getPercent().toFixed(0);
        //this.iPercent = sc.getPercent()
        //scInfos.innerHTML = percent + '%';
        //scInfos.innerHTML =  this.scratchStore.getValue().name;

      })
    }).catch((error) => {
      // image not loaded
      //alert(error.message);
      //scInfos.innerHTML =  this.scratchStore.getValue().name;
    });


  }

  cancel() {
    this.closeAntimate().then(o => {
      this.scratchStore.update({ visible: false })
    })

  }

  show() {
    let comp = document.getElementById('orwi-scratch')
    comp.style.opacity = "1"
    comp.classList.add("animate__bounceInUp")

    setTimeout(() => {
      comp.classList.remove("animate__bounceInUp")
    }, 1000);
  }

  ngAfterViewInit() {


  }

  closeAntimate() {
    return new Promise((resolve, reject) => {
      let comp = document.getElementById('orwi-scratch')
      if (!comp) return
      comp.classList.add("animate__bounceOutDown")
      setTimeout(() => {
        comp.classList.remove("animate__bounceOutDown")
        resolve(true)
      }, 500);
    })
  }






}
