import { Injectable } from "@angular/core";
import { OpenNativeSettings } from "@awesome-cordova-plugins/open-native-settings/ngx";
import { DeviceInfo } from "@capacitor/device";
import {
  AlertController,
  IonicSafeString,
  LoadingController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { LoadingOptions } from "@ionic/core";
import { TranslocoService } from "@ngneat/transloco";
import { environment } from "../../environments/environment";
import { feedback } from "./dto/orwi-feedback";
import { App } from "@capacitor/app";

export const OFFER_IMAGES = "offerImages";
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  feedback: feedback;

  DeviceInfo: iDeviceInfo;

  PushToken = "";

  darkTheme = false;
  startupQrCode = "";

  networkConnected = true;
  googleMapSdkInjected = false;
  googleApiKey = "AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c";

  oLoading: HTMLIonLoadingElement;
  activeGroup;
  ipAddress = "";

  orwiContent: any;

  tempBanner =
    "https://www.turizmgunlugu.com/wp-content/uploads/2017/12/mila-restoran.jpg";

  orwiApp = { version: "", production: false };
  tempProductImage: string;
  giftCardImage: string;

  constructor(
    private nativeSettings: OpenNativeSettings,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private translocoService: TranslocoService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {
    //this.googleApiKey = "AIzaSyBlcvydJ5f6zvpIa0uj0rZG2Kk6O-I5Prk"

    this.orwiApp.production = environment.production;
    this.orwiApp.version = environment.appVersion;
  }

  consolelog(
    log1,
    log2 = undefined,
    log3 = undefined,
    type: "public" | "private" = "private",
    logType: "log" | "error" | "warn" = "log"
  ) {
    if (environment.production) return;

    if (logType == "log") {
      console.log(log1, log2, log3);
    } else if (logType == "error") {
      console.error(log1, log2, log3);
    } else {
      console.warn(log1, log2, log3);
    }
  }

  loadingTimeout = undefined;

  async showLoading(m: string = "") {
    // const globalLoading = document.getElementById("orwi-global-loading");
    // console.log("global", globalLoading);
    // if (globalLoading) {
    //   if (!globalLoading.classList.contains("show")) {
    //     this.loadingTimeout = setTimeout(() => {
    //       this.closeLoading();
    //     }, 30000);
    //     globalLoading.classList.add("show");
    //   }
    // }

    // return;
    if (this.oLoading) return;
    let options: LoadingOptions = {
      message: new IonicSafeString(
        '<ion-img src="/assets/icon/orwi-jump.png" alt="loading..."></ion-img>'
      ),
      cssClass: "custom-loading",
      translucent: false,
      showBackdrop: false,
      spinner: null,
      keyboardClose: true,
      duration: 30000,
    };

    let opt2 = {
      cssClass: "loading-controller",
      message: "",
      duration: 30000,
    };

    this.oLoading = await this.loadingCtrl.create(options);
    await this.oLoading.present();
  }

  closeLoading(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // const globalLoading = document.getElementById("orwi-global-loading");

      // setTimeout(() => {
      //   if (globalLoading) {
      //     clearTimeout(this.loadingTimeout);
      //     globalLoading.classList.remove("show");
      //   }
      // }, 1000);

      // return resolve(true);
      if (this.oLoading) {
        this.oLoading.dismiss();
        this.oLoading = undefined;
      }
      setTimeout(() => {
        if (this.oLoading) {
          this.oLoading.dismiss();
          this.oLoading = undefined;
        }
        resolve(true);
      }, 500);
    });
  }

  async toast(
    header,
    message,
    position: "top" | "middle" | "bottom",
    color: "danger" | "warning" | "success" | "dark",
    duration: number = 2000,
    button: boolean = false
  ) {
    let config = {
      header: header,
      message: message,
      position: position,
      color: color,
      animated: true,
      cssClass: "toast",
    };

    if (button) {
      config["buttons"] = [
        {
          text: this.translocoService.translate("Yes"),
          role: "cancel",
          handler: () => {
            this.consolelog("Cancel clicked");
          },
        },
        {
          text: this.translocoService.translate("No"),
          role: "cancel",
          handler: () => {
            this.consolelog("Cancel clicked");
          },
        },
      ];
    } else {
      config["duration"] = duration;
    }

    const toast = await this.toastCtrl.create(config);
    toast.present();
  }

  async toastButton(
    header,
    message,
    position: "top" | "middle" | "bottom",
    color: "danger" | "warning" | "success",
    okey = true,
    cancel = true,
    done = false
  ) {
    let duration = 0;
    let button = true;

    let buttons = [];

    return new Promise(async (resolve, reject) => {
      let config = {
        header: header,
        message: message,
        position: position,
        color: color,
        animated: true,
        cssClass: "toast",
      };

      if (cancel) {
        buttons.push({
          text: this.translocoService.translate("No"),
          role: "cancel",
          handler: () => {
            this.consolelog("Cancel clicked");
            resolve("no");
          },
        });
      }

      if (okey) {
        buttons.push({
          text: this.translocoService.translate("Yes"),
          role: "okey",
          handler: () => {
            this.consolelog("Okey clicked");
            resolve("yes");
          },
        });
      }

      if (done) {
        buttons.push({
          text: this.translocoService.translate("Done"),
          role: "okey",
          handler: () => {
            this.consolelog("Okey clicked");
            resolve("yes");
          },
        });
      }

      if (buttons) {
        config["buttons"] = buttons;
      } else {
        config["duration"] = duration;
      }

      const toast = await this.toastCtrl.create(config);
      toast.present();
    });
  }

  async openNoAccessServerModal(retryFunction: () => void) {
    const alertRef = await this.alertController.create({
      header: this.translocoService.translate("Oops!"),
      message: this.translocoService.translate(
        "There is a problem, try again after a while"
      ),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translocoService.translate("Exit"),
          role: "descructive",
          handler: () => {
            App.exitApp();
          },
        },
        {
          text: this.translocoService.translate("Try Again"),
          role: "descructive",
          handler: () => {
            retryFunction();
          },
        },
      ],
    });

    await alertRef.present();
  }

  openNativeAppSettings() {
    this.nativeSettings
      .open("application_details")
      .then((res) => {
        this.consolelog(res);
      })
      .catch((err) => {
        this.consolelog(err);
      });
  }

  titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        if (word[0]) {
          return word.replace(word[0], word[0].toUpperCase());
        } else {
          return word;
        }
      })
      .join(" ");
  }

  notTurkishCharacter(value: string): string {
    let replacedVal = value
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
    return replacedVal;
  }

  cleanGsm(gsm: string): string {
    return gsm
      .replace("+", "")
      .replace("(", "")
      .replace("(", "")
      .replace("-", "")
      .replace(" ", "")
      .trim();
  }

  shakeInput(id) {
    let el = document.getElementById(id);
    if (!el) return;
    if (el.classList.contains("animate__animated")) {
      el.classList.add("animate__animated");
    }

    el.classList.add("animate__shakeX");

    setTimeout(() => {
      el.classList.remove("animate__shakeX");
    }, 1000);
  }
}
export interface iDeviceInfo extends DeviceInfo {
  uuid?: string;
  appVersion?: string;
  appBuild?: string;
  appName?: string;
}
