import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ScratchState, ScratchStore } from './scratch.store';

@Injectable({ providedIn: 'root' })
export class ScratchQuery extends Query<ScratchState> {


    scratchVisible$ = this.select("visible")
    


    constructor(protected store: ScratchStore) {
        super(store);
    }

}

