export class SurveySchema {
  id = "";
  createDate = new Date();
  isActive = false;
  name = "";
  description = "";
  startDate = new Date();
  endDate = new Date();
  isDeleted = false;
  deleteDate = new Date();
  deleteUser = "";
  tigger: "popup" | "link" = "link";
  language = "tr";
  storeIds = new Array<string>();
  loginRequired = false;
  items = new Array<SurveyItemScehma>();
  storeId: string = "";
  constructor() {
    this.tigger = "popup";
  }
}
//questions
export class SurveyItemScehma {
  sequenceNo = 0;
  categoryName = "";
  id? = "";
  name = "";
  isRequired = true;
  isMulti = true;
  isOtherFree = false;
  input: SurveyInput;

  constructor() {
    this.categoryName = "";
    this.name = "";
    this.isRequired = false;
    this.isMulti = false;
    this.isOtherFree = false;
  }
}

//answer Inputs
export class SurveyInput {
  sequenceNo = 0;
  inputType: InputType;
  caption = "";
  selectList = new Array<string>();
}

export type InputType =
  | "option"
  | "text"
  | "check"
  | "list"
  | "star"
  | "spin"
  | "file"
  | "branch-list";

export const InputTypes = [
  "option",
  "text",
  "check",
  "list",
  "star",
  "spin",
  "file",
  "branch-list",
].map((el) => ({ name: el, id: el }));

export class SurveyAnswer {
  createDate = new Date();
  userId = "";
  storeId = "";
  surveyId = "";
  surveyName = "";
  answers = new Array<SurveyAnswerItem>();
  isSuccess? = false;
}

export class SurveyAnswerItem {
  surveyId = "";
  surveyName = "";

  itemId = "";
  itemName = "";

  inputType = "";
  answer: string | string[] = "";
}
