export class Product {
  id: string
  name: string
  description: string
  group: string
  price: number
  dlPrice: number
  slfPrice: number
  vat: number
  image: string
  smallImage: string;
  imageUrl: string;
  imageMiniUrl: string;
  printer: string
  selectCount: number
  modifierType: string
  modifierIndex: number
  winStamp: number //win stamp
  scratch: number //win stamp
  spendPoints: number //spend value
  kerzzID: string
  temp_onFolioCount = 0
  imageUse = false
  soldOut = false
  pointPercent = 0
  cookingTime = 0
  attributes = []
  isShowDelivery = false
  isShowInStore = false
  couponCode = ""
  order = 0
  outOfOrder: boolean = false
  content: string = ''
}

export class Price {
  selfService: 0.0
  deliveryService: 0.0
  tableService: 0.0
}

export class ProductGroup {
  id: string = ""
  name: string = ""
  parentID: string = ""
  order: number = 0
  kerzzID: string = ""
  image: string = ""
  base64: string = ""
  hide: boolean = false
  soldOut = false
}


export class ModifierCover {
  id: string = "";
  auto: boolean = false;
  maxSelect: number = 0;
  minSelect: number = 0;
  remain: number = 0;
  productID: string = "";
  productGroupID: string = "";
  modif: Modifier[] = []
}

export class Modifier {
  type: 'custom' | 'product' | 'group'
  id: string;
  index: number;
  productGroup: string;
  group: string;
  maxSelect: number;
  minSelect: number;
  remain: number;
  must: boolean;
  auto: boolean;
  filteredProducts: any[];
  detachable: boolean
  canBeAdded: boolean
  priceless: boolean
  products: ModifierProduct[] = []
}

export class ModifierProduct {

  productID: string;
  productName: string;
  price: number;
  priceless: boolean;
  detachable: boolean;
  canBeAdded: boolean;
  selected: boolean
  temp_rowID = ""
  subModifiers: Modifier[] = []

}

export class Advices {
  group: string
  description: string
  order: number
}


export class ModifierGroup {
  order = 0
  modifierGroup: Modifier[] = []
}

export class groupImages {
  itemId = ""
  base64 = ""
}