import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { OrwiLocation as CacheOrwiLocation, UserLocation } from '../dto/orwi-address';
import { orwiLocation, locationEvent } from '../dto/orwi-location';
import { FbService } from '../events/fb.service';
import { LocationEventsService } from '../events/location-events.service';
import { GlobalService } from '../global.service';
import { OrwiService } from '../orwi.service';
import { AppService } from '../utils/app.service';


@Injectable({
  providedIn: 'root'
})
export class LocationService {


  internalCacheOrwiLocation: CacheOrwiLocation
  isChanged = true
  oldLocation: orwiLocation
  currentLocation: orwiLocation
  selectedLocation: orwiLocation
  locationStatus: 'success' | 'prompt' | 'denied' | 'error' | 'unset' | 'loading' | 'custom' = "unset"
  maxDistance: number = 25

  constructor(
    private fb: FbService,
    private glb: GlobalService, private plt: Platform,
    private as: AppService,
    private os: OrwiService,
    private locationEvents: LocationEventsService, private http: HttpClient,) { }

  async getCurrentPosition(ask = false): Promise<locationEvent> {

    let str = `mobile: ${this.plt.is("mobile")},android: ${this.plt.is("android")}, desktop: ${this.plt.is("desktop")}, capacitor: ${this.plt.is("capacitor")} - ios: ${this.plt.is("ios")} - iphone: ${this.plt.is("iphone")}`
    //this.glb.toast("pl", str, "middle", "success",60000)
    this.glb.consolelog("location-request", str)

    if ((this.as.deeplinkQrCode && this.as.deeplinkQrCode !== "") 
    || (this.glb.startupQrCode && this.glb.startupQrCode !== "")) {
      this.locationStatus = "unset"
      return
    }


    if (this.plt.is("capacitor")) {

      if (this.plt.is("android")) {
        this.locationStatus = "loading"
        this.locationEvents.currentLocationEvent.next({ location: undefined, status: "loading", isChanged: this.isChanged })
        return this.getLocation()
      } else {
        return this.currentPositionForCapacitor(ask)
      }
    } else {
      this.locationStatus = "loading"
      this.locationEvents.currentLocationEvent.next({ location: undefined, status: "loading", isChanged: this.isChanged })
      return this.getLocation()
    }
  }


  async currentPositionForCapacitor(ask = false): Promise<locationEvent> {


    return new Promise(async (resolve, reject) => {
      this.locationStatus = "loading"
      this.locationEvents.currentLocationEvent.next({ location: undefined, status: "loading", isChanged: this.isChanged })


      const hasPermission = await Geolocation.checkPermissions()


      if (hasPermission.location == "prompt") {

        if (ask) {

          this.getLocation().then(o => {
            resolve(o)
          }, e => {
            resolve({ location: undefined, status: "error", isChanged: this.isChanged })
          })


        } else {
          this.locationStatus = "prompt"
          this.locationEvents.currentLocationEvent.next({ location: undefined, status: "prompt", isChanged: this.isChanged })
          resolve({ location: undefined, status: "prompt", isChanged: this.isChanged })
        }

      } else if (hasPermission.location == "denied") {
        this.fb.locationRequest(false)
        this.locationStatus = "denied"
        this.locationEvents.currentLocationEvent.next({ location: undefined, status: "denied", isChanged: this.isChanged })
        resolve({ location: undefined, status: "denied", isChanged: this.isChanged })


        if (ask) {

          this.getLocation().then(o => {
            resolve(o)
          }, e => {
            resolve({ location: undefined, status: "error", isChanged: this.isChanged })
          })


        }


      } else if (hasPermission.location = "granted") {
        this.getLocation().then(o => {
          resolve(o)
        }, e => {
          reject(e)
        })
      }
    })

  }


  private async getLocation(): Promise<locationEvent> {
    return new Promise(async (resolve, reject) => {
      let coordinates
      try {


        this.oldLocation = this.currentLocation ? this.currentLocation : undefined

        coordinates = await Geolocation.getCurrentPosition();
        this.currentLocation = { longitude: coordinates.coords.longitude, latitude: coordinates.coords.latitude }
        this.locationEvents.currentLocationEvent.next({ location: this.currentLocation, status: "success", isChanged: this.isChanged })
        this.locationStatus = "success"
        this.isChanged = true

        if (this.oldLocation) {
          let km = this.distance(this.oldLocation.latitude, this.oldLocation.longitude, this.currentLocation.latitude, this.currentLocation.longitude, "K")
          if (km < 0.3) {
            this.isChanged = false
          }
        }
        this.fb.locationRequest(true)

        resolve({ location: this.currentLocation, status: "success", isChanged: this.isChanged })

      } catch (error) {
        this.fb.locationRequest(false)

        this.glb.consolelog(error)

        if (this.plt.is("capacitor")) {
          reject("error")
          this.locationStatus = "error"
          this.locationEvents.currentLocationEvent.next({ location: undefined, status: "error", error: error, isChanged: this.isChanged })

        } else {

          this.locationStatus = "denied"
          this.locationEvents.currentLocationEvent.next({ location: undefined, status: "denied", error: error, isChanged: this.isChanged })


        }

      }
    })

  }

  watchPosition() {
    const wait = Geolocation.watchPosition({}, (position, err) => {
      this.glb.consolelog("watch", position)
    })
  }

   roundRange(amount: number) {
    return Math.round((amount + Number.EPSILON) * 100) / 100
}

  useDefaultPosition() {

    let lng = 29.069172884826358
    let lat = 41.12125536571792

    this.selectedLocation = { latitude: lat, longitude: lng }


    this.getReverseGeoCode(lat, lng).then(o => {
      this.locationEvents.selectMapPoisition.next(o)
    })

  }

  // getReverseGeoCode(lat, lng) {
  //   this.glb.consolelog("reverse-location", lat, lng)
  //   return new Promise((resolve, reject) => {
  //     var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c"
  //     this.http.get(apiUrl).subscribe((response: any) => {
  //       resolve(response.results)
  //     }, e => {
  //       reject("error")
  //     });
  //   })
  // }

  distance(lat1, lon1, lat2, lon2, unit = "K") {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }
  }





  Obsolate_getReverseGeoCode(lat, lng) {
    this.glb.consolelog("getReverseGeoCode", lat, lng)
    return new Promise((resolve, reject) => {

      let ul: UserLocation[] = []
      let locs = localStorage.getItem("locations")
      if (locs) {
        let ul: UserLocation[] = JSON.parse(locs)
        let finded = ul.find(o => o.lat == lat && o.lng == lng)
        if (finded) {
          console.log("getReverseGeoCode Resolved Local", finded.name)
          resolve(finded.name)
          return
        }
      }

      var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c"
      this.http.get(apiUrl).subscribe((response: any) => {

        let xul: UserLocation = {
          lat: lat,
          lng: lng,
          name: response.results
        }

        ul.push(xul)
        localStorage.setItem("locations", JSON.stringify(ul))
        console.log("getReverseGeoCode Resolved API", response.result)
        resolve(response.results)


      }, e => {
        reject("error")
      });

    })

  }


  getReverseGeoCode(lat, lng) {
    this.glb.consolelog("getReverseGeoCode", lat, lng)
    return new Promise(async (resolve, reject) => {

      if (this.internalCacheOrwiLocation?.lat == lat && this.internalCacheOrwiLocation?.lng == lng) {        
        console.log("getReverseGeoCode Resolved with internal Cache", this.internalCacheOrwiLocation)
        if (this.internalCacheOrwiLocation.address)
        {
          resolve(this.internalCacheOrwiLocation.address)
          return
        }       
      }

      let ol: CacheOrwiLocation = await this.getOrwiGeoCode(lat, lng)

      if (ol) {
        console.log("getReverseGeoCode Resolved with Cache", ol)
        this.fb.reverseGeocode(true)
        this.internalCacheOrwiLocation = ol
        resolve(ol.address)
        return
      }

      var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c"
      this.http.get(apiUrl).subscribe((response: any) => {

        this.saveOrwiGeoCode(lat, lng, response.results)
        this.fb.reverseGeocode(false)
        this.internalCacheOrwiLocation = {lat:lat, lng: lng, address: response.result}
        console.log("getReverseGeoCode Resolved with API", response.result)
        resolve(response.results)


      }, e => {
        reject("error")
      });

    })

  }

  getReverseGeoCodeGeopify(lat,lng) : Promise<any> {
      return new Promise((resolve, reject) => {
  debugger
        let apiUrl = "https://api.geoapify.com/v1/geocode/reverse?lat=" + lat + "&lon=" + lng + "&apiKey=b1854df07dbe4833be2ec390d5797386&type=street"
  
        this.http.get(apiUrl).subscribe((response: any) => {
          
  debugger
  console.log("success geo",response)
  resolve(response)

  
        }, e => {
          console.log("getReverseGeoCodeGeopify",e)
        });
  
      })
  }



  saveOrwiGeoCode(lat: number, lng: number, address) {
    lat = parseFloat(lat.toFixed(5))
    lng = parseFloat(lng.toFixed(5))
    
    
    return this.os.serviceRequestPromise("/api/address/saveGeoLocationInfo", { lat: lat, lng: lng, address: address })
  }

  getOrwiGeoCode(lat: number, lng:number): Promise<CacheOrwiLocation> {
    lat = parseFloat(lat.toFixed(5))
    lng = parseFloat(lng.toFixed(5))
    

    return new Promise((resolve, reject) => {
      this.os.serviceRequestPromise("/api/address/getGeoLocationInfo", { lat: lat, lng: lng }).then((o: any) => {

        if (o.response) {
          resolve(o.response)
        } else {
          resolve(undefined)
        }
      })

    })

  }
}



